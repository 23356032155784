import Path from '../../utils/Path';

const api = Path.preset({ basename: '' });

export const dealsSearch = api(`limm-control/contracts/deal-contracts/search`);

export const dealCreate = api(`/limm-control/contracts/deal-contracts`);

export const getDeal = (contractId?: string) =>
  api(`/limm-control/contracts/deal-contracts/${contractId}`);

export const dealRepaymentsSearch = (contractId?: string) =>
  api(`/limm-control/contracts/deal-contracts/${contractId}/repayments/search`);

export const getDealSpec = (contractId?: string) =>
  api(`/limm-control/contracts/deal-contracts/${contractId}/specs`);

export const dealAdditionalContractsSearch = (contractId?: string) =>
  api(
    `/limm-control/contracts/deal-contracts/${contractId}/additional-contracts/search`
  );

export const downloadDeals = api(
  `/limm-control/contracts/deal-contracts/statements`
);

export const dealRepaymentsStatement = (contractId?: string) =>
  api(
    `/limm-control/contracts/deal-contracts/${contractId}/repayments/statements`
  );

export const dealRepayments = (contractId?: string) =>
  api(`/limm-control/contracts/deal-contracts/${contractId}/repayments`);

export const dealAdditionalStatement = (contractId?: string) =>
  api(
    `/limm-control/contracts/deal-contracts/${contractId}/additional-contracts/statements`
  );
export const dealAdditionalContractCreate = (contractId?: string) =>
  api(
    `/limm-control/contracts/deal-contracts/${contractId}/additional-contracts`
  );

export const dealContractClose = (contractId?: string) =>
  api(`/limm-control/contracts/deal-contracts/${contractId}/close`);

export const dealContractDelete = (contractId?: string) =>
  api(`/limm-control/contracts/deal-contracts/${contractId}`);

import Path from '../../utils/Path';

const api = Path.preset({ basename: '' });

const CONTRACTS_PATH = '/limm-control/contracts';

export const contracts = api(CONTRACTS_PATH);

export const contractsSearch = api(`${CONTRACTS_PATH}/doc-contracts/search`);

export const contractsTopSearch = api(`${CONTRACTS_PATH}/top-contracts/search`);

export const contractsTop = api(`${CONTRACTS_PATH}/top-contracts`);

export const contractsLowSearch = api(`${CONTRACTS_PATH}/low-contracts/search`);

export const contractsLowMutations = api(`${CONTRACTS_PATH}/low-contracts`);

export const contractsStatuses = api(`${CONTRACTS_PATH}/statuses`);

export const contractsTopStatusesSearch = api(
  `${CONTRACTS_PATH}/top-contracts/statuses/search`
);

export const contractsLowStatusesSearch = api(
  `${CONTRACTS_PATH}/low-contracts/statuses/search`
);

export const contractTop = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}`);
export const contractTopDetails = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/details`);

export const contractLow = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/low-contracts/${contractId}`);

export const contractsLowAdditionalSearch = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/low-contracts/${contractId}/additional-contracts`);

export const contractsTopAdditionalSearch = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/additional-contracts`);

export const contractTopRelatedContracts = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/low-contracts`);

export const contractTopRepaymentsSearch = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/repayments/search`);

export const contractLowRepaymentsSearch = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/low-contracts/${contractId}/repayments/search`);

export const debtRepaymentTopMutate = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/repayments`);

export const debtRepaymentLowerMutate = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/low-contracts/${contractId}/repayments`);

export const topContractCustomers = api(
  `${CONTRACTS_PATH}/top-contracts/availiable-customers`
);

export const lowContractCustomers = api(
  `${CONTRACTS_PATH}/low-contracts/availiable-customers`
);

export const productByClient = api(`${CONTRACTS_PATH}/top-contracts/products`);

export const decisionAuthCodes = (decisionId?: string) =>
  api(`/limm-control/decisions/${decisionId}/auth-codes`);

export const cancelContractTop = (contractId: string) =>
  api(`${CONTRACTS_PATH}/${contractId}`);

export const closeContractTop = (contractId: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/close`);

export const closeContractLow = (contractId: string) =>
  api(`${CONTRACTS_PATH}/low-contracts/${contractId}/close`);

export const deleteContractLow = (contractId: string) =>
  api(`${CONTRACTS_PATH}/low-contracts/${contractId}`);

export const createAdditionalContractTop = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/additional-contracts`);

export const createAdditionalContractLow = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/low-contracts/${contractId}/additional-contracts`);

export const createContractTop = api(`${CONTRACTS_PATH}/top-contracts`);

export const topContractPledges = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/pledges`);

export const additionalContractsStatement = (contractId?: string) =>
  api(
    `${CONTRACTS_PATH}/top-contracts/${contractId}/additional-contracts/statement`
  );

export const repaymentsStatement = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/repayments/statement`);

export const topLowContractsStatement = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/top-contracts/${contractId}/low-contracts/statement`);

export const additionalContractsLowStatement = (contractId?: string) =>
  api(
    `${CONTRACTS_PATH}/low-contracts/${contractId}/additional-contracts/statements`
  );

export const repaymentsLowStatement = (contractId?: string) =>
  api(`${CONTRACTS_PATH}/low-contracts/${contractId}/repayments/statements`);
